import { Container, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Attribution = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Typography
        margin={4}
        variant="body2"
        color="textSecondary"
        align="center"
      >
        {t('attribution.createdBy')}{' '} <Link href="https://github.com/jhaals/yopass">Johan Haals</Link><br />
	Кастомизация: <Link href="">Andy.V</Link>
      </Typography>
    </Container>
  );
};
